import React from 'react'
import Layout from '../../components/Layout'
import Head from '../../components/Head'
import SEOWebpage from '../../components/SEO/webpage'
import { useStaticQuery, graphql } from "gatsby"

export default function Careers() {

  const pageData = useStaticQuery(graphql`
    query ContactSuccessQuery {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `)

  const pageUrl = pageData.site.siteMetadata.siteUrl + "/contact-success/";

  return (
    <Layout>
      <Head
        title="Contact Success"
        pageDesc="Contact Success"
        url={pageUrl}
      />
      <SEOWebpage
        title="Contact Success"
        meta_description="Contact Success"
        url={pageUrl}
      />
      <div className="pageSection">
        <h1>Thank you for reaching out!</h1>
        <h4>We got your contact form request. Someone from our team will reach out to you shortly!</h4>
      </div>
    </Layout>
  )
}
